<template>
    <div class="wrapper -encuentra-mentor">

        <section class="encabezado -encuentra-mentor">
            <h1 class="with-btn">Encuentra un mentor/a</h1>
            <a href="https://gestionmentoring.uc3m.es/login/mentee" target="_blank" class="btn -light-orange">Regístrate como mentee</a>
        </section>

        <section class="tips-container">

            <h2 class="title">¿Por qué ser mentee?</h2>

            <div class="container -xs row-start ais">
                <article>
                    <div class="icon">
                        <img src="/img/icon-flexibilidad-light.svg" alt="Encuentros">
                    </div>
                    <h3>Habilidades</h3>
                    <p>Desarrollo de habilidades asociadas al rol profesional.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-networking-light.svg" alt="Actividades de ocio y cultura">
                    </div>
                    <h3>Networking</h3>
                    <p>Amplía tu red de contactos. Son una fuente de oportunidades profesionales.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-acompanamiento-light.svg" alt="Acciones formativas">
                    </div>
                    <h3>Orientación</h3>
                    <p>Profesional y sobre procesos de selección.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-talleres-light.svg" alt="Becas Alumni UC3M">
                    </div>
                    <h3>Perspectiva Empresarial</h3>
                    <p>Benefíciate del conocimiento de otros, gana nuevas perspectivas.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-habilidades-light.svg" alt="Networking">
                    </div>
                    <h3>Sector</h3>
                    <p>Mejora los conocimientos de un sector concreto.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-certificacion-light.svg" alt="Mentoring">
                    </div>
                    <h3>Conocimiento</h3>
                    <p>Aumenta tus conocimientos obteniendo una visión global en el ámbito de la empresa.</p>
                </article>
            </div>
        </section>

        <section class="banner-compromiso -mentee">
            <div class="container column-center">
                <h2 class="title -white">Compromiso</h2>
                <div class="tips row-center">
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-implicacion.svg" alt="Implicación">
                        </div>
                        <h3>Implicación</h3>
                        <p>Valora y respeta el compromiso voluntario de tu mentor/a.</p>
                    </article>
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-proactividad.svg" alt="Proactividad">
                        </div>
                        <h3>Proactividad</h3>
                        <p>Identifica tus intereses y objetivos profesionales con antelación a las reuniones.</p>
                    </article>
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-meses-white.svg" alt="Flexibilidad">
                        </div>
                        <h3>Flexibilidad</h3>
                        <p>Con tu mentor/a para conciliar horario y lugar de reuniones.</p>
                    </article>
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-responsabilidad.svg" alt="Responsabilidad">
                        </div>
                        <h3>Responsabilidad</h3>
                        <p>Valora previamente que podrás cumplir con las sesiones mínimas establecidas.</p>
                    </article>
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-evaluacion.svg" alt="Evaluación">
                        </div>
                        <h3>Evaluación</h3>
                        <p>Evaluación de la mentoría a su finalización.</p>
                    </article>
                </div>
            </div>
        </section>

        <slider-pasos></slider-pasos>

        <section class="requisitos">
            <div class="container">
                <h2 class="title">Requisitos</h2>
                <div class="contenedor-requisitos row-center ais">
                    <article>
                        <div class="imagen -blue -text">
                            <span>Estudiantes de grado, a partir de tercer curso, </span>
                            <span>o Estudiantes de postgrado, </span>
                            <span>o Egresados/as</span>
                        </div>
                        <h3>Pertenecer al colectivo UC3M</h3>
                    </article>
                    <article>
                        <div class="imagen -light -text">
                            <span>Cumplir con un mínimo de 3 sesiones en un máximo de 3 meses</span>
                        </div>
                        <h3>Disponibilidad</h3>
                    </article>
                </div>
            </div>
        </section>


        <slider-testimonios></slider-testimonios>


        <section class="texto-imagen">

            <div class="container row-start">

                <div class="contenedor-texto column-start">
                    <h2 class="title">Certificación</h2>
                    <p>Podrán obtener este certificado, tras finalizar la mentoría, quienes hayan realizado las tres sesiones mínimas obligatorias de la mentoría y hayan cumplimentado el cuestionario de satisfacción del programa. Reconoce la participación del mentee en el mismo y podrá descargarse como un diploma de participación en formato PDF desde el área privada del mentee.</p>
                </div>

                <div class="contenedor-imagen">
                    <img src="/img/imagen-certificacion.jpg" alt="Certificación">
                </div>
            </div>

        </section>

        <section class="galeria -light-orange">
            <div class="container">
                <h2 class="title -white">Networking</h2>
                <p>Para fortalecer los vínculos UC3M y crear un espacio colaborativo intergeneracional donde generar sinergias y potenciar el mayor activo de la Universidad: las personas. 
                    Súmate a nuestra Red de mentores y mentees y forma parte del movimiento #SoyAlumniUC3M.
                    
                    
                </p>
            </div>
            <galeria :id="'networking'"></galeria>

        </section>

        <faqs :show="['mentee']"></faqs>

    </div>
</template>

<script>

import slidertestimonios from '@/components/slider-testimonios-mentees';
import faqs from '@/components/faqs';
import galeria from '@/components/galeria';
import sliderpasos from '@/components/slider-pasos';

    export default {
        name : 'ser-mentor',
        components : {
            'slider-testimonios' : slidertestimonios,
            'faqs': faqs,
            'galeria': galeria,
            'slider-pasos': sliderpasos,
        }
    }
</script>