<template>
    <section class="slider-testimonios -mentees">


        <div class="container">
            <h2 class="subtitle">Testimonios de Mentees</h2>
            <slick
                ref="slick"
                :options="slickOptions">
                <div class="slide">
                    <h5>Para mí el Programa Mentoring Alumni UC3M ha sido la oportunidad de conocer a Álvaro, un profesional apasionado de su trabajo que, con mucha amabilidad, me ha ayudado a definir mis expectativas laborales, a identificar oportunidades, a enriquecer mi CV y a encarar el proceso de búsqueda de empleo de una forma más exitosa. En definitiva, una experiencia verdaderamente buena para hacer networking, aprender, ampliar visiones y conocer personas que han marcado mi trayectoria académica.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/laura-ferre.jpg" alt="Laura Ferre Sanjuán">
                        <h3>Laura Ferre Sanjuán</h3>
                        <p>Máster Universitario en Geopolítica y Estudios Estratégicos</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Ha sido una gran experiencia que recomendaría a todo el mundo. Mi tutor  me ha ayudado a encontrar mi camino en el mundo laboral que me espera tras la universidad, transmitiéndome su experiencia y consejos. Es genial contar con gente comprometida que se vuelca en actividades tan enriquecedoras. ¡Me encantaría participar como mentora en el futuro!
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/ana-ahumada.jpg" alt="Ana Ahumada Sanz">
                        <h3>Ana Ahumada Sanz</h3>
                        <p>Doble Grado en Derecho y Administración de Empresas</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Gracias al Programa Mentoring he tenido la oportunidad de estar en contacto con profesionales que, además de orientarme y aconsejarme, me han inspirado y motivado tanto a nivel personal como profesional. Han transformado mi incertidumbre respecto a “¿y después de graduarme qué?” en objetivos, metas a alcanzar. 
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/georgiana-livia.jpg" alt="Georgiana Livia Cruceanu">
                        <h3>Georgiana Livia Cruceanu</h3>
                        <p>Grado en Sociología</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Gracias al Programa Mentoring por haberme puesto en contacto con un gran mentor, de mi mismo sector profesional, y que me ha ayudado en gran medida a conseguir oportunidades laborales, mediante la mejora de mi CV y mi recomendación a distintas empresas. Mi mentor siempre ha estado disponible para resolver cualquiera de mis dudas y, su ayuda, me ha servido para finalizar el máster con un contrato laboral.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/alberto-parkel.jpg" alt="Alberto Parkel Fernández">
                        <h3>Alberto Parkel Fernández</h3>
                        <p>Máster en Marketing</p>
                    </div>
                </div>
            </slick>

        </div>

    </section>
</template>

<script>

import Slick from 'vue-slick';

import 'slick-carousel/slick/slick.css';

    export default {
        name : "slider-empresas",
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots : true,
                    arrows : false,
                    adaptiveHeight: false
                },
            }
        },
        components: { Slick },
    }
</script>