var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper -encuentra-mentor"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('slider-pasos'),_vm._m(3),_c('slider-testimonios'),_vm._m(4),_c('section',{staticClass:"galeria -light-orange"},[_vm._m(5),_c('galeria',{attrs:{"id":'networking'}})],1),_c('faqs',{attrs:{"show":['mentee']}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"encabezado -encuentra-mentor"},[_c('h1',{staticClass:"with-btn"},[_vm._v("Encuentra un mentor/a")]),_c('a',{staticClass:"btn -light-orange",attrs:{"href":"https://gestionmentoring.uc3m.es/login/mentee","target":"_blank"}},[_vm._v("Regístrate como mentee")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tips-container"},[_c('h2',{staticClass:"title"},[_vm._v("¿Por qué ser mentee?")]),_c('div',{staticClass:"container -xs row-start ais"},[_c('article',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-flexibilidad-light.svg","alt":"Encuentros"}})]),_c('h3',[_vm._v("Habilidades")]),_c('p',[_vm._v("Desarrollo de habilidades asociadas al rol profesional.")])]),_c('article',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-networking-light.svg","alt":"Actividades de ocio y cultura"}})]),_c('h3',[_vm._v("Networking")]),_c('p',[_vm._v("Amplía tu red de contactos. Son una fuente de oportunidades profesionales.")])]),_c('article',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-acompanamiento-light.svg","alt":"Acciones formativas"}})]),_c('h3',[_vm._v("Orientación")]),_c('p',[_vm._v("Profesional y sobre procesos de selección.")])]),_c('article',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-talleres-light.svg","alt":"Becas Alumni UC3M"}})]),_c('h3',[_vm._v("Perspectiva Empresarial")]),_c('p',[_vm._v("Benefíciate del conocimiento de otros, gana nuevas perspectivas.")])]),_c('article',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-habilidades-light.svg","alt":"Networking"}})]),_c('h3',[_vm._v("Sector")]),_c('p',[_vm._v("Mejora los conocimientos de un sector concreto.")])]),_c('article',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-certificacion-light.svg","alt":"Mentoring"}})]),_c('h3',[_vm._v("Conocimiento")]),_c('p',[_vm._v("Aumenta tus conocimientos obteniendo una visión global en el ámbito de la empresa.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner-compromiso -mentee"},[_c('div',{staticClass:"container column-center"},[_c('h2',{staticClass:"title -white"},[_vm._v("Compromiso")]),_c('div',{staticClass:"tips row-center"},[_c('article',{staticClass:"tip"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-implicacion.svg","alt":"Implicación"}})]),_c('h3',[_vm._v("Implicación")]),_c('p',[_vm._v("Valora y respeta el compromiso voluntario de tu mentor/a.")])]),_c('article',{staticClass:"tip"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-proactividad.svg","alt":"Proactividad"}})]),_c('h3',[_vm._v("Proactividad")]),_c('p',[_vm._v("Identifica tus intereses y objetivos profesionales con antelación a las reuniones.")])]),_c('article',{staticClass:"tip"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-meses-white.svg","alt":"Flexibilidad"}})]),_c('h3',[_vm._v("Flexibilidad")]),_c('p',[_vm._v("Con tu mentor/a para conciliar horario y lugar de reuniones.")])]),_c('article',{staticClass:"tip"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-responsabilidad.svg","alt":"Responsabilidad"}})]),_c('h3',[_vm._v("Responsabilidad")]),_c('p',[_vm._v("Valora previamente que podrás cumplir con las sesiones mínimas establecidas.")])]),_c('article',{staticClass:"tip"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/icon-evaluacion.svg","alt":"Evaluación"}})]),_c('h3',[_vm._v("Evaluación")]),_c('p',[_vm._v("Evaluación de la mentoría a su finalización.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"requisitos"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v("Requisitos")]),_c('div',{staticClass:"contenedor-requisitos row-center ais"},[_c('article',[_c('div',{staticClass:"imagen -blue -text"},[_c('span',[_vm._v("Estudiantes de grado, a partir de tercer curso, ")]),_c('span',[_vm._v("o Estudiantes de postgrado, ")]),_c('span',[_vm._v("o Egresados/as")])]),_c('h3',[_vm._v("Pertenecer al colectivo UC3M")])]),_c('article',[_c('div',{staticClass:"imagen -light -text"},[_c('span',[_vm._v("Cumplir con un mínimo de 3 sesiones en un máximo de 3 meses")])]),_c('h3',[_vm._v("Disponibilidad")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"texto-imagen"},[_c('div',{staticClass:"container row-start"},[_c('div',{staticClass:"contenedor-texto column-start"},[_c('h2',{staticClass:"title"},[_vm._v("Certificación")]),_c('p',[_vm._v("Podrán obtener este certificado, tras finalizar la mentoría, quienes hayan realizado las tres sesiones mínimas obligatorias de la mentoría y hayan cumplimentado el cuestionario de satisfacción del programa. Reconoce la participación del mentee en el mismo y podrá descargarse como un diploma de participación en formato PDF desde el área privada del mentee.")])]),_c('div',{staticClass:"contenedor-imagen"},[_c('img',{attrs:{"src":"/img/imagen-certificacion.jpg","alt":"Certificación"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"title -white"},[_vm._v("Networking")]),_c('p',[_vm._v("Para fortalecer los vínculos UC3M y crear un espacio colaborativo intergeneracional donde generar sinergias y potenciar el mayor activo de la Universidad: las personas. Súmate a nuestra Red de mentores y mentees y forma parte del movimiento #SoyAlumniUC3M. ")])])
}]

export { render, staticRenderFns }